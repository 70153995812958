import React, { useState } from "react";
import Sticky from 'react-stickynode';
import { ScrollProvider } from "../context/ScrollContext";
import BlockText from "./block-text";
import ExhibitionHero from "./exhibition-hero";
import ExhibitionInfo from "./exhibition-info";
import ImageCarouselSection from "./image-carousel-section";
import ImageGallerySection from "./image-gallery-section";
import VideoEmbedSection from "./video-embed-section";
import Container from "./container";
import SectionMenu from "./section-menu";

import * as styles from "./exhibition.module.css";

function Exhibition({
  title,
  slug,
  startedAt, 
  endedAt, 
  supportedBy, 
  _rawIntroduction, 
  artists, 
  _rawSections,
  featuredMedia,
  virtualGallery
}) {
  const [activeSection, setActiveSection] = useState(null);
  const artistBio = artists[0]._rawBio;

  const pageSections = (_rawSections || [])
    .map((c, i) => {
      let el = null;
      switch (c._type) {
        case "videoEmbedSection":
          el = <VideoEmbedSection key={c._key} {...c} setActiveSection={setActiveSection} />;
          break;
        case "imageCarousel":
          el = <ImageCarouselSection key={c._key} {...c} setActiveSection={setActiveSection} />;
          break;
        case "imageGallery":
          el = <ImageGallerySection key={c._key} {...c} setActiveSection={setActiveSection} />;
          break;
        default:
          el = null;
      }
      return el;
    });

  return (
    <ScrollProvider>
      <ExhibitionHero 
        featuredMedia={featuredMedia}
        slug={slug}
        title={title}
        artists={artists}
        virtualGallery={virtualGallery}
        isExhibitionPage />
      <div className={styles.mainContent}>
        {pageSections && <SectionMenu items={_rawSections} activeSection={activeSection} />}

        {virtualGallery && <Sticky enabled={true} top={59}>
          <div className={styles.cta}>
            <a href={`/exhibitions/${slug.current}/virtual-gallery`}>
              <span>Enter gallery</span>
            </a>
          </div>
        </Sticky>}

        <Container>
          <ExhibitionInfo startedAt={startedAt} endedAt={endedAt} supportedBy={supportedBy} />
          <BlockText blocks={_rawIntroduction} />
          {artistBio && (
            <>
              <hr />
              <h4>About the Artist</h4>
              <BlockText blocks={artistBio} />
            </>
          )}
        </Container>
        
        {pageSections}
      </div>
      </ScrollProvider>
  );
}

export default Exhibition;
