import React from "react";
import PageAnchorSection from "./pageAnchorSection";
import slugify from 'react-slugify';
import BlockText from "./block-text";
import Container from "./container";
import Carousel from "./carousel";
import CarouselFigure from "./carousel-figure";
import VideoEmbed from "./video-embed";

const ImageCarouselSection = ({
  text,
  images,
  title,
  setActiveSection
}) => {
  const sectionID = slugify(title);

  return (
    <PageAnchorSection id={sectionID} setActiveSection={setActiveSection}>
      <Container>
        <h4>{title}</h4>
        <BlockText blocks={text} />
      </Container>

      <Carousel>
        {images.map((item) => {
          if (item._type == 'figure') {
            return <CarouselFigure image={item} />
          } else {
            return <VideoEmbed node={item} contained={false} />
          }
        })}
      </Carousel>
    </PageAnchorSection>
  );
};

export default ImageCarouselSection;