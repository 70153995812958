import React from "react";
import { graphql } from "gatsby";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";

import clientConfig from "../../client-config";
import GraphQLErrorList from "../components/graphql-error-list";
import WelcomeAnimation from "../components/welcome-animation";
import BlockText from "../components/block-text";
import SEO from "../components/seo";
import Marquee from "../components/marquee";
import ExhibitionHero from "../components/exhibition-hero";
import ExhibitionMap from "../components/exhibition-map";
import VimeoPlayer from '../components/vimeo-player';
import AboutSectionFeature from "../components/about-section-feature";

import Layout from "../containers/layout";

import * as styles from "./index.module.css";

const {isFuture} = require('date-fns');

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    frontpage: sanityFrontpage {
      announcements
      _rawAnnouncementBarText
      exhibitionMap {
        _key
        _rawText
        image {
          asset {
            _id
          }
          crop {
            top
            right
            left
            bottom
            _type
            _key
          }
          hotspot {
            _key
            _type
            height
            width
            x
            y
          }
        }
      }
      aboutSectionFeatures {
        title
        link {
          slug {
            current
          }
        }
        image {
          alt
          asset {
            _id
          }
          crop {
            _key
            _type
            bottom
            left
            right
            top
          }
          hotspot {
            _key
            _type
            height
            width
            x
            y
          }
        }
        _rawDescription
      }
      exhibition {
        virtualGallery
        publishedAt
        startedAt
        endedAt
        supportedBy {
          name
        }
        slug {
          current
        }
        title
        artists {
          name
        }
        featuredMedia {
          largeImage {
            alt
            asset {
              _id
            }
            crop {
              _key
              _type
              bottom
              left
              right
              top
            }
            hotspot {
              _key
              _type
              height
              width
              x
              y
            }
          }
          smallImage {
            alt
            asset {
              _id
            }
            crop {
              _key
              _type
              bottom
              left
              right
              top
            }
            hotspot {
              _key
              _type
              height
              width
              x
              y
            }
          }
          videoUrlHD
          videoUrlSD
        }
      }
      featuredMedia {
        _rawDescription
        videoUrlHD
        videoUrlSD
        image {
          alt
          asset {
            _id
          }
          crop {
            _key
            _type
            bottom
            left
            right
            top
          }
          hotspot {
            _key
            _type
            height
            width
            x
            y
          }
        }
      }
    }
  }
`;

const featuredMediaImage = featuredMedia => {
  let img = null;
  if (featuredMedia && featuredMedia.image && featuredMedia.image.asset) {
    const imageData = getGatsbyImageData(
      featuredMedia.image,
      { maxWidth: 1800 },
      clientConfig.sanity
    );

    img = (
      <GatsbyImage
        className={styles.featuredMedia}
        image={imageData}
        alt={featuredMedia.image.alt}
      />
    );
  }
  return img;
};

const featuredMediaVideo = featuredMedia => {
  let videoPlayer = null;

  if (featuredMedia && featuredMedia.videoUrlHD) {
    videoPlayer = <VimeoPlayer 
      url={featuredMedia.videoUrlHD}
    />
  }

  return videoPlayer;
}

const IndexPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const frontpage = data.frontpage;
  const { 
    exhibition, 
    aboutSectionFeatures,
    featuredMedia, 
    announcements, 
    _rawAnnouncementBarText,
    exhibitionMap
  } = frontpage;

  const featuredMediaImageAsset = featuredMediaImage(featuredMedia);
  const featuredMediaVideoPlayer = featuredMediaVideo(featuredMedia);

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      {/* Title (hidden) for SEO purposes */}
      <h1 hidden>{site.title}</h1>

      {/* Announcements */}
      {announcements && _rawAnnouncementBarText && <Marquee text={_rawAnnouncementBarText} />}

      {/* Current Exhibition */}
      {exhibition && <ExhibitionHero {...exhibition} showNav={!isFuture(exhibition.publishedAt)} />}

      {exhibitionMap.length > 2 && exhibition ? <ExhibitionMap slug={exhibition.slug} rooms={exhibitionMap} /> : ''}

      {/* Featured Media */}
      {featuredMedia && (
        <div className={styles.featuredMedia}>
          {featuredMediaImageAsset && featuredMediaImageAsset}
          {featuredMediaVideoPlayer && featuredMediaVideoPlayer}
          <div className={styles.featuredMediaDescription}>
            <BlockText blocks={featuredMedia._rawDescription} />
          </div>
        </div>
      )}

      {/* About Section Features */}
      <div className={styles.aboutSectionFeatures}>
        {aboutSectionFeatures && aboutSectionFeatures.map((item, index) => <AboutSectionFeature key={index} data={item} />)}
      </div>
      <WelcomeAnimation />
    </Layout>
  );
};

export default IndexPage;
