import React, { useEffect, createRef, useState, useContext } from "react";

import lottie from "lottie-web";
import {cn} from "../lib/helpers";
import ThemeContext from "../context/ThemeContext";
import animation from "../assets/vca-full.json";
import * as styles from "./welcome-animation.module.css";

const WelcomeAnimation = () => {
  const theme = useContext(ThemeContext);
  let animationContainer = createRef();
  const [showAnimation, setShowAnimation] = useState(true);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: animation,
    });

    anim.addEventListener('complete', () => {
      setShowAnimation(!showAnimation);
      
      const delay = setTimeout(() => {
        theme.hideAnimation();
        clearTimeout(delay);
      }, 1000);
    });

    return () => anim.destroy(); // optional clean up for unmounting
  }, []);

  return (
    <>
      {theme.showAnimation && <div className={cn(styles.root, showAnimation ? '' : styles.hide)} ref={animationContainer} />}
    </>
  );
};

export default WelcomeAnimation;