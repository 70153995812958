import React, { useRef, useEffect } from "react";

function buildThresholdList() {
  let thresholds = [];
  let numSteps = 20;

  for (let i=1.0; i<=numSteps; i++) {
    let ratio = i/numSteps;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
}

const PageAnchorSection = ({ children, id, setActiveSection }) => {
  const ref = useRef();
 
  // mount 
  useEffect(() => {
    const cachedRef = ref.current;

    let options = {
      rootMargin: '0px 0px 50% 0px',
      thresholds: buildThresholdList()
    }

    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.isIntersecting) {
          setActiveSection(cachedRef.id);
        } else {
          setActiveSection(null);
        }
      }, options);

    observer.observe(cachedRef);

    // unmount
    return function() {
      observer.unobserve(cachedRef);
    }
  }, []);

  return (
    <section id={id} ref={ref}>
      {children}
    </section>
  );
};

export default PageAnchorSection;
